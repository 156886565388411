import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Image } from '../../Image';

type Data = {
  xeroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const ConnectionFlow: React.FC = () => {
  const { xeroImage } = useStaticQuery<Data>(
    graphql`
      {
        xeroImage: file(relativePath: { eq: "xero-connection-flow.png" }) {
          childImageSharp {
            gatsbyImageData(width: 400, layout: FIXED)
          }
        }
      }
    `,
  );
  return (
    <Image image={xeroImage.childImageSharp.gatsbyImageData} alt="connected" />
  );
};
