/* eslint-disable import/prefer-default-export */
export const Button = {
  baseStyle: {
    borderRadius: '3xl',
  },
  sizes: {
    xs: {
      fontSize: 'xs',
      padding: '2',
      minWidth: '60px',
    },
    sm: {
      minWidth: '80px',
      fontSize: 'xs',
    },
    md: {
      fontSize: 'md',
      fontWeight: '500',
      padding: '6',
      minWidth: '150px',
    },
  },
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'brand.blue',
      bg: 'white',
      color: 'brand.blue',
      boxShadow: '0px 5px 15px #000F2F14',
    },
    solid: {
      bg: 'brand.blue',
      boxShadow: '0 4px 14px 0 rgb(0 118 255 / 36%)',
      color: 'white',
      _hover: {
        bg: 'brand.blue',
        opacity: '0.8',
      },
    },
    danger: {
      bg: 'brand.red',
      color: 'white',
      _hover: {
        bg: 'brand.red',
        opacity: '0.8',
      },
    },
    success: {
      bg: 'brand.green',
      color: 'white',
      _hover: {
        bg: 'brand.green',
        opacity: '0.8',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
