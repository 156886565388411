import { Screen31 } from "../../../../src/components/images/support/certifications/Screen31.generated";
import { Screen32 } from "../../../../src/components/images/support/certifications/Screen32.generated";
import { Screen33 } from "../../../../src/components/images/support/certifications/Screen33.generated";
import { Screen34 } from "../../../../src/components/images/support/certifications/Screen34.generated";
import { Screen35 } from "../../../../src/components/images/support/certifications/Screen35.generated";
import { Screen36 } from "../../../../src/components/images/support/certifications/Screen36.generated";
import { Screen37 } from "../../../../src/components/images/support/certifications/Screen37.generated";
import * as React from 'react';
export default {
  Screen31,
  Screen32,
  Screen33,
  Screen34,
  Screen35,
  Screen36,
  Screen37,
  React
};