import { Screen1 } from "../../../../src/components/images/support/businesses/Screen1.generated";
import { Screen2 } from "../../../../src/components/images/support/businesses/Screen2.generated";
import { Screen3 } from "../../../../src/components/images/support/businesses/Screen3.generated";
import { Screen4 } from "../../../../src/components/images/support/businesses/Screen4.generated";
import { Screen5 } from "../../../../src/components/images/support/businesses/Screen5.generated";
import * as React from 'react';
export default {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  React
};