import { Visatask1 } from "../../../../src/components/images/support/visa/Visatask1.generated";
import { Visatask2 } from "../../../../src/components/images/support/visa/Visatask2.generated";
import { Visatask3 } from "../../../../src/components/images/support/visa/Visatask3.generated";
import { Visatask4 } from "../../../../src/components/images/support/visa/Visatask4.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Visatask1,
  Visatask2,
  Visatask3,
  Visatask4,
  SupportFooter,
  React
};