import { Screen1 } from "../../../../src/components/images/support/certifications/Screen1.generated";
import { Screen2 } from "../../../../src/components/images/support/certifications/Screen2.generated";
import { Screen3 } from "../../../../src/components/images/support/certifications/Screen3.generated";
import { Screen4 } from "../../../../src/components/images/support/certifications/Screen4.generated";
import { Screen5 } from "../../../../src/components/images/support/certifications/Screen5.generated";
import { Screen6 } from "../../../../src/components/images/support/certifications/Screen6.generated";
import { Screen7 } from "../../../../src/components/images/support/certifications/Screen7.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
  SupportFooter,
  React
};