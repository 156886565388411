import { Identity1 } from "../../../../src/components/images/support/workflow-templates/Identity1.generated";
import { Identity2a } from "../../../../src/components/images/support/workflow-templates/Identity2a.generated";
import { Identity2 } from "../../../../src/components/images/support/workflow-templates/Identity2.generated";
import { Identity3a } from "../../../../src/components/images/support/workflow-templates/Identity3a.generated";
import { Identity3 } from "../../../../src/components/images/support/workflow-templates/Identity3.generated";
import { Identity4 } from "../../../../src/components/images/support/workflow-templates/Identity4.generated";
import { Identity5 } from "../../../../src/components/images/support/workflow-templates/Identity5.generated";
import { Identity6 } from "../../../../src/components/images/support/workflow-templates/Identity6.generated";
import { Identity7 } from "../../../../src/components/images/support/workflow-templates/Identity7.generated";
import { Identity8 } from "../../../../src/components/images/support/workflow-templates/Identity8.generated";
import { Identity9 } from "../../../../src/components/images/support/workflow-templates/Identity9.generated";
import { Identity10 } from "../../../../src/components/images/support/workflow-templates/Identity10.generated";
import { Identity11 } from "../../../../src/components/images/support/workflow-templates/Identity11.generated";
import { Identity12 } from "../../../../src/components/images/support/workflow-templates/Identity12.generated";
import { Identity13 } from "../../../../src/components/images/support/workflow-templates/Identity13.generated";
import { Identity14 } from "../../../../src/components/images/support/workflow-templates/Identity14.generated";
import { Identity17 } from "../../../../src/components/images/support/workflow-templates/Identity17.generated";
import { Identity18 } from "../../../../src/components/images/support/workflow-templates/Identity18.generated";
import * as React from 'react';
export default {
  Identity1,
  Identity2a,
  Identity2,
  Identity3a,
  Identity3,
  Identity4,
  Identity5,
  Identity6,
  Identity7,
  Identity8,
  Identity9,
  Identity10,
  Identity11,
  Identity12,
  Identity13,
  Identity14,
  Identity17,
  Identity18,
  React
};