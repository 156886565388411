import { Copytask1 } from "../../../../src/components/images/support/tasks/Copytask1.generated";
import { Copytask2 } from "../../../../src/components/images/support/tasks/Copytask2.generated";
import { Copytask3 } from "../../../../src/components/images/support/tasks/Copytask3.generated";
import { Copytask4 } from "../../../../src/components/images/support/tasks/Copytask4.generated";
import { Copytask5 } from "../../../../src/components/images/support/tasks/Copytask5.generated";
import { Copytask6 } from "../../../../src/components/images/support/tasks/Copytask6.generated";
import { Copytask7 } from "../../../../src/components/images/support/tasks/Copytask7.generated";
import * as React from 'react';
export default {
  Copytask1,
  Copytask2,
  Copytask3,
  Copytask4,
  Copytask5,
  Copytask6,
  Copytask7,
  React
};