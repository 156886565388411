import { Screen15 } from "../../../../src/components/images/support/xero/Screen15.generated";
import { Screen20 } from "../../../../src/components/images/support/xero/Screen20.generated";
import { Screen21 } from "../../../../src/components/images/support/xero/Screen21.generated";
import { Screen22 } from "../../../../src/components/images/support/xero/Screen22.generated";
import { Screen23 } from "../../../../src/components/images/support/xero/Screen23.generated";
import * as React from 'react';
export default {
  Screen15,
  Screen20,
  Screen21,
  Screen22,
  Screen23,
  React
};