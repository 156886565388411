import { Emplogin1 } from "../../../../src/components/images/support/users/Emplogin1.generated";
import { Emplogin2 } from "../../../../src/components/images/support/users/Emplogin2.generated";
import { Emplogin3 } from "../../../../src/components/images/support/users/Emplogin3.generated";
import { Emplogin4 } from "../../../../src/components/images/support/users/Emplogin4.generated";
import * as React from 'react';
export default {
  Emplogin1,
  Emplogin2,
  Emplogin3,
  Emplogin4,
  React
};