/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { theme } from '../theme';
import { Fonts } from '../theme/global';

export const wrapPageElement: React.FC = ({ element }: any) => {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Fonts />
      {element}
    </ChakraProvider>
  );
};
