import { Screen8 } from "../../../../src/components/images/support/certifications/Screen8.generated";
import { Screen9 } from "../../../../src/components/images/support/certifications/Screen9.generated";
import { Screen10 } from "../../../../src/components/images/support/certifications/Screen10.generated";
import { Screen11 } from "../../../../src/components/images/support/certifications/Screen11.generated";
import { Screen12 } from "../../../../src/components/images/support/certifications/Screen12.generated";
import { Screen13 } from "../../../../src/components/images/support/certifications/Screen13.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen8,
  Screen9,
  Screen10,
  Screen11,
  Screen12,
  Screen13,
  SupportFooter,
  React
};