import { Screen14 } from "../../../../src/components/images/support/certifications/Screen14.generated";
import { Screen15 } from "../../../../src/components/images/support/certifications/Screen15.generated";
import { Screen16 } from "../../../../src/components/images/support/certifications/Screen16.generated";
import { Screen17 } from "../../../../src/components/images/support/certifications/Screen17.generated";
import { Screen18 } from "../../../../src/components/images/support/certifications/Screen18.generated";
import { Screen19 } from "../../../../src/components/images/support/certifications/Screen19.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen14,
  Screen15,
  Screen16,
  Screen17,
  Screen18,
  Screen19,
  SupportFooter,
  React
};