export const Heading = {
  sizes: {
    md: {
      fontSize: '2.25em',
      fontWeight: '700',
    },
    lg: {
      fontSize: ['2em', '3.2em', '3.2em'],
      fontWeight: '700',
    },
    xl: {
      fontSize: ['2.6em', '3.8em', '3.8em'],
      fontWeight: '700',
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
