import * as React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export const SupportFooter: React.FC<{ linkTo: string; linkText: string }> = ({
  linkTo,
  linkText,
}) => {
  return (
    <Flex
      as="footer"
      bgColor="#252C41"
      minH="70px"
      align="center"
      justifyContent="space-around"
      my={5}
      px={3}
    >
      <Link to={linkTo} color="white" mr={5} as={GatsbyLink}>
        {linkText}
      </Link>
    </Flex>
  );
};
