// eslint-disable-next-line @typescript-eslint/no-use-before-define
import * as React from 'react';
import { Flex } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type ImageProps = {
  image: IGatsbyImageData;
  alt: string;
};

export const Image: React.FC<ImageProps> = ({ image, alt }) => {
  return (
    <Flex justifyContent="center" wrap="wrap" mb={6}>
      <div
        style={{
          width: `1200px`,
          marginBottom: `1.45rem`,
        }}
      >
        <GatsbyImage image={image} alt={alt} />
      </div>
    </Flex>
  );
};
