import { Screen1 } from "../../../../src/components/images/support/tasks/Screen1.generated";
import { Screen2 } from "../../../../src/components/images/support/tasks/Screen2.generated";
import { Screen3 } from "../../../../src/components/images/support/tasks/Screen3.generated";
import { Screen4 } from "../../../../src/components/images/support/tasks/Screen4.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  SupportFooter,
  React
};