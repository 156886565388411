import { Licrej1 } from "../../../../src/components/images/support/certifications/Licrej1.generated";
import { Licrej2 } from "../../../../src/components/images/support/certifications/Licrej2.generated";
import { Licrej3 } from "../../../../src/components/images/support/certifications/Licrej3.generated";
import { Licrej4 } from "../../../../src/components/images/support/certifications/Licrej4.generated";
import { Licrej5 } from "../../../../src/components/images/support/certifications/Licrej5.generated";
import * as React from 'react';
export default {
  Licrej1,
  Licrej2,
  Licrej3,
  Licrej4,
  Licrej5,
  React
};