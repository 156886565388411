import { Screen17 } from "../../../../src/components/images/support/getting-started/Screen17.generated";
import { Screen18 } from "../../../../src/components/images/support/getting-started/Screen18.generated";
import { Screen19 } from "../../../../src/components/images/support/getting-started/Screen19.generated";
import { Screen20 } from "../../../../src/components/images/support/getting-started/Screen20.generated";
import { Screen21 } from "../../../../src/components/images/support/getting-started/Screen21.generated";
import { Screen23 } from "../../../../src/components/images/support/getting-started/Screen23.generated";
import { Screen24 } from "../../../../src/components/images/support/getting-started/Screen24.generated";
import { Screen25 } from "../../../../src/components/images/support/getting-started/Screen25.generated";
import * as React from 'react';
export default {
  Screen17,
  Screen18,
  Screen19,
  Screen20,
  Screen21,
  Screen23,
  Screen24,
  Screen25,
  React
};