import { Sms1 } from "../../../../src/components/images/support/users/Sms1.generated";
import { Reass1 } from "../../../../src/components/images/support/workflows/Reass1.generated";
import { Reass2 } from "../../../../src/components/images/support/workflows/Reass2.generated";
import { Reass3 } from "../../../../src/components/images/support/workflows/Reass3.generated";
import { Reass4 } from "../../../../src/components/images/support/workflows/Reass4.generated";
import { Reass5 } from "../../../../src/components/images/support/workflows/Reass5.generated";
import { Reass6 } from "../../../../src/components/images/support/workflows/Reass6.generated";
import { Reass7 } from "../../../../src/components/images/support/workflows/Reass7.generated";
import * as React from 'react';
export default {
  Sms1,
  Reass1,
  Reass2,
  Reass3,
  Reass4,
  Reass5,
  Reass6,
  Reass7,
  React
};