import { extendTheme } from '@chakra-ui/react';

import { Button } from './button';
import { Heading } from './heading';

const fonts = {
  body: 'Roboto, sans-serif',
  heading: 'Termoli, sans-serif',
  mono: 'Menlo, monospace',
};

const styles = {
  global: {
    body: {
      bg: 'gray.50',
    },
  },
};

const brandColors = {
  900: '#2c6cfb',
  egg: '#fffdee',
  pink: '#ff8877',
  blue: '#2c6cfb',
  'light-blue': '#709BFF',
  gray: '#7d8cb5',
  green: '#06A77D',
  red: '#F56565',
  yellow: '#FEDF46',
  orange: '#F46F2A',
  'light-gray': '#EDF2F7',
  white: '#FFFFFF',
};

export const theme = extendTheme({
  colors: {
    brand: brandColors,
  },
  fonts,
  styles,
  components: {
    Button,
    Heading,
  },
});
