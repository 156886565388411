exports.components = {
  "component---src-docs-support-advanced-use-cases-migrate-employees-from-one-xero-org-to-another-mdx": () => import("./../../../src/docs/support/advanced-use-cases/migrate-employees-from-one-xero-org-to-another.mdx" /* webpackChunkName: "component---src-docs-support-advanced-use-cases-migrate-employees-from-one-xero-org-to-another-mdx" */),
  "component---src-docs-support-businesses-add-a-business-mdx": () => import("./../../../src/docs/support/businesses/add-a- business.mdx" /* webpackChunkName: "component---src-docs-support-businesses-add-a-business-mdx" */),
  "component---src-docs-support-certifications-adding-certifications-mdx": () => import("./../../../src/docs/support/certifications/adding-certifications.mdx" /* webpackChunkName: "component---src-docs-support-certifications-adding-certifications-mdx" */),
  "component---src-docs-support-certifications-approving-certifications-mdx": () => import("./../../../src/docs/support/certifications/approving-certifications.mdx" /* webpackChunkName: "component---src-docs-support-certifications-approving-certifications-mdx" */),
  "component---src-docs-support-certifications-assigning-certifications-to-an-employee-mdx": () => import("./../../../src/docs/support/certifications/assigning-certifications-to-an-employee.mdx" /* webpackChunkName: "component---src-docs-support-certifications-assigning-certifications-to-an-employee-mdx" */),
  "component---src-docs-support-certifications-assigning-certifications-to-positions-mdx": () => import("./../../../src/docs/support/certifications/assigning-certifications-to-positions.mdx" /* webpackChunkName: "component---src-docs-support-certifications-assigning-certifications-to-positions-mdx" */),
  "component---src-docs-support-certifications-certification-basics-mdx": () => import("./../../../src/docs/support/certifications/certification-basics.mdx" /* webpackChunkName: "component---src-docs-support-certifications-certification-basics-mdx" */),
  "component---src-docs-support-certifications-updating-certifications-mdx": () => import("./../../../src/docs/support/certifications/updating-certifications.mdx" /* webpackChunkName: "component---src-docs-support-certifications-updating-certifications-mdx" */),
  "component---src-docs-support-frequently-asked-questions-mdx": () => import("./../../../src/docs/support/frequently-asked-questions.mdx" /* webpackChunkName: "component---src-docs-support-frequently-asked-questions-mdx" */),
  "component---src-docs-support-getting-started-creating-a-position-mdx": () => import("./../../../src/docs/support/getting-started/creating-a-position.mdx" /* webpackChunkName: "component---src-docs-support-getting-started-creating-a-position-mdx" */),
  "component---src-docs-support-getting-started-creating-a-workflow-mdx": () => import("./../../../src/docs/support/getting-started/creating-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-getting-started-creating-a-workflow-mdx" */),
  "component---src-docs-support-getting-started-getting-started-mdx": () => import("./../../../src/docs/support/getting-started/getting-started.mdx" /* webpackChunkName: "component---src-docs-support-getting-started-getting-started-mdx" */),
  "component---src-docs-support-getting-started-invite-an-employee-mdx": () => import("./../../../src/docs/support/getting-started/invite-an-employee.mdx" /* webpackChunkName: "component---src-docs-support-getting-started-invite-an-employee-mdx" */),
  "component---src-docs-support-index-mdx": () => import("./../../../src/docs/support/index.mdx" /* webpackChunkName: "component---src-docs-support-index-mdx" */),
  "component---src-docs-support-integrations-xero-disconnecting-mdx": () => import("./../../../src/docs/support/integrations/xero/disconnecting.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-disconnecting-mdx" */),
  "component---src-docs-support-integrations-xero-faq-mdx": () => import("./../../../src/docs/support/integrations/xero/faq.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-faq-mdx" */),
  "component---src-docs-support-integrations-xero-importing-mdx": () => import("./../../../src/docs/support/integrations/xero/importing.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-importing-mdx" */),
  "component---src-docs-support-integrations-xero-refreshing-mdx": () => import("./../../../src/docs/support/integrations/xero/refreshing.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-refreshing-mdx" */),
  "component---src-docs-support-integrations-xero-setup-mdx": () => import("./../../../src/docs/support/integrations/xero/setup.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-setup-mdx" */),
  "component---src-docs-support-integrations-xero-syncing-mdx": () => import("./../../../src/docs/support/integrations/xero/syncing.mdx" /* webpackChunkName: "component---src-docs-support-integrations-xero-syncing-mdx" */),
  "component---src-docs-support-positions-creating-a-position-mdx": () => import("./../../../src/docs/support/positions/creating-a-position.mdx" /* webpackChunkName: "component---src-docs-support-positions-creating-a-position-mdx" */),
  "component---src-docs-support-subscriptions-adding-a-subscription-mdx": () => import("./../../../src/docs/support/subscriptions/adding-a-subscription.mdx" /* webpackChunkName: "component---src-docs-support-subscriptions-adding-a-subscription-mdx" */),
  "component---src-docs-support-subscriptions-cancel-a-subscription-mdx": () => import("./../../../src/docs/support/subscriptions/cancel-a-subscription.mdx" /* webpackChunkName: "component---src-docs-support-subscriptions-cancel-a-subscription-mdx" */),
  "component---src-docs-support-subscriptions-invoices-mdx": () => import("./../../../src/docs/support/subscriptions/invoices.mdx" /* webpackChunkName: "component---src-docs-support-subscriptions-invoices-mdx" */),
  "component---src-docs-support-subscriptions-linking-business-to-subscription-mdx": () => import("./../../../src/docs/support/subscriptions/linking-business-to-subscription.mdx" /* webpackChunkName: "component---src-docs-support-subscriptions-linking-business-to-subscription-mdx" */),
  "component---src-docs-support-tasks-adding-tasks-mdx": () => import("./../../../src/docs/support/tasks/adding-tasks.mdx" /* webpackChunkName: "component---src-docs-support-tasks-adding-tasks-mdx" */),
  "component---src-docs-support-tasks-copying-tasks-mdx": () => import("./../../../src/docs/support/tasks/copying-tasks.mdx" /* webpackChunkName: "component---src-docs-support-tasks-copying-tasks-mdx" */),
  "component---src-docs-support-tasks-skipping-tasks-mdx": () => import("./../../../src/docs/support/tasks/skipping-tasks.mdx" /* webpackChunkName: "component---src-docs-support-tasks-skipping-tasks-mdx" */),
  "component---src-docs-support-tasks-task-types-mdx": () => import("./../../../src/docs/support/tasks/task-types.mdx" /* webpackChunkName: "component---src-docs-support-tasks-task-types-mdx" */),
  "component---src-docs-support-users-archive-a-user-mdx": () => import("./../../../src/docs/support/users/archive-a-user.mdx" /* webpackChunkName: "component---src-docs-support-users-archive-a-user-mdx" */),
  "component---src-docs-support-users-employee-login-mdx": () => import("./../../../src/docs/support/users/employee-login.mdx" /* webpackChunkName: "component---src-docs-support-users-employee-login-mdx" */),
  "component---src-docs-support-users-invite-a-supervisor-mdx": () => import("./../../../src/docs/support/users/invite-a-supervisor.mdx" /* webpackChunkName: "component---src-docs-support-users-invite-a-supervisor-mdx" */),
  "component---src-docs-support-users-invite-an-admin-mdx": () => import("./../../../src/docs/support/users/invite-an-admin.mdx" /* webpackChunkName: "component---src-docs-support-users-invite-an-admin-mdx" */),
  "component---src-docs-support-users-invite-an-employee-mdx": () => import("./../../../src/docs/support/users/invite-an-employee.mdx" /* webpackChunkName: "component---src-docs-support-users-invite-an-employee-mdx" */),
  "component---src-docs-support-users-message-a-user-mdx": () => import("./../../../src/docs/support/users/message-a-user.mdx" /* webpackChunkName: "component---src-docs-support-users-message-a-user-mdx" */),
  "component---src-docs-support-users-notifications-mdx": () => import("./../../../src/docs/support/users/notifications.mdx" /* webpackChunkName: "component---src-docs-support-users-notifications-mdx" */),
  "component---src-docs-support-users-qr-code-invite-mdx": () => import("./../../../src/docs/support/users/qr-code-invite.mdx" /* webpackChunkName: "component---src-docs-support-users-qr-code-invite-mdx" */),
  "component---src-docs-support-users-reset-user-mfa-mdx": () => import("./../../../src/docs/support/users/reset-user-mfa.mdx" /* webpackChunkName: "component---src-docs-support-users-reset-user-mfa-mdx" */),
  "component---src-docs-support-users-user-basics-mdx": () => import("./../../../src/docs/support/users/user-basics.mdx" /* webpackChunkName: "component---src-docs-support-users-user-basics-mdx" */),
  "component---src-docs-support-videos-add-a-video-mdx": () => import("./../../../src/docs/support/videos/add-a-video.mdx" /* webpackChunkName: "component---src-docs-support-videos-add-a-video-mdx" */),
  "component---src-docs-support-visa-checks-adding-a-visa-details-task-to-a-workflow-mdx": () => import("./../../../src/docs/support/visa-checks/adding-a-visa-details-task-to-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-visa-checks-adding-a-visa-details-task-to-a-workflow-mdx" */),
  "component---src-docs-support-visa-checks-completing-a-visa-check-mdx": () => import("./../../../src/docs/support/visa-checks/completing-a-visa-check.mdx" /* webpackChunkName: "component---src-docs-support-visa-checks-completing-a-visa-check-mdx" */),
  "component---src-docs-support-visa-checks-reviewing-visa-check-history-mdx": () => import("./../../../src/docs/support/visa-checks/reviewing-visa-check-history.mdx" /* webpackChunkName: "component---src-docs-support-visa-checks-reviewing-visa-check-history-mdx" */),
  "component---src-docs-support-visa-checks-visa-check-basics-mdx": () => import("./../../../src/docs/support/visa-checks/visa-check-basics.mdx" /* webpackChunkName: "component---src-docs-support-visa-checks-visa-check-basics-mdx" */),
  "component---src-docs-support-workflow-templates-client-identity-verification-mdx": () => import("./../../../src/docs/support/workflow-templates/client-identity-verification.mdx" /* webpackChunkName: "component---src-docs-support-workflow-templates-client-identity-verification-mdx" */),
  "component---src-docs-support-workflows-copy-a-workflow-mdx": () => import("./../../../src/docs/support/workflows/copy-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-copy-a-workflow-mdx" */),
  "component---src-docs-support-workflows-create-a-workflow-mdx": () => import("./../../../src/docs/support/workflows/create-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-create-a-workflow-mdx" */),
  "component---src-docs-support-workflows-create-self-service-workflow-mdx": () => import("./../../../src/docs/support/workflows/create-self-service-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-create-self-service-workflow-mdx" */),
  "component---src-docs-support-workflows-reassign-a-workflow-mdx": () => import("./../../../src/docs/support/workflows/reassign-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-reassign-a-workflow-mdx" */),
  "component---src-docs-support-workflows-send-a-reminder-for-a-workflow-mdx": () => import("./../../../src/docs/support/workflows/send-a-reminder-for-a-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-send-a-reminder-for-a-workflow-mdx" */),
  "component---src-docs-support-workflows-starting-a-self-service-workflow-mdx": () => import("./../../../src/docs/support/workflows/starting-a-self-service-workflow.mdx" /* webpackChunkName: "component---src-docs-support-workflows-starting-a-self-service-workflow-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-electronic-signature-tsx": () => import("./../../../src/pages/electronic-signature.tsx" /* webpackChunkName: "component---src-pages-electronic-signature-tsx" */),
  "component---src-pages-end-user-license-agreement-tsx": () => import("./../../../src/pages/end-user-license-agreement.tsx" /* webpackChunkName: "component---src-pages-end-user-license-agreement-tsx" */),
  "component---src-pages-features-client-identity-verification-index-tsx": () => import("./../../../src/pages/features/client-identity-verification/index.tsx" /* webpackChunkName: "component---src-pages-features-client-identity-verification-index-tsx" */),
  "component---src-pages-features-employee-onboarding-index-tsx": () => import("./../../../src/pages/features/employee-onboarding/index.tsx" /* webpackChunkName: "component---src-pages-features-employee-onboarding-index-tsx" */),
  "component---src-pages-features-licence-and-certification-management-index-tsx": () => import("./../../../src/pages/features/licence-and-certification-management/index.tsx" /* webpackChunkName: "component---src-pages-features-licence-and-certification-management-index-tsx" */),
  "component---src-pages-features-tax-file-number-declaration-index-tsx": () => import("./../../../src/pages/features/tax-file-number-declaration/index.tsx" /* webpackChunkName: "component---src-pages-features-tax-file-number-declaration-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-xero-tsx": () => import("./../../../src/pages/integrations/xero.tsx" /* webpackChunkName: "component---src-pages-integrations-xero-tsx" */),
  "component---src-pages-partner-index-tsx": () => import("./../../../src/pages/partner/index.tsx" /* webpackChunkName: "component---src-pages-partner-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-website-terms-of-use-tsx": () => import("./../../../src/pages/website-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-website-terms-of-use-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

