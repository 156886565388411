/** DO NOT EDIT -- GENERATED FILE */
/* eslint-disable */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Image } from '../../Image';

type Data = {
  startingImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const Visatask3: React.FC = () => {
  const { startingImage } = useStaticQuery<Data>(
    graphql`
      {
        startingImage: file(relativePath: { eq: "support/visa/visatask3.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  return (
    <Image image={startingImage.childImageSharp.gatsbyImageData} alt="screenshot" />
  );
};
