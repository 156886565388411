import { Screenskip1 } from "../../../../src/components/images/support/tasks/Screenskip1.generated";
import { Screenskip2 } from "../../../../src/components/images/support/tasks/Screenskip2.generated";
import { Screenskip3 } from "../../../../src/components/images/support/tasks/Screenskip3.generated";
import { Screenskip4 } from "../../../../src/components/images/support/tasks/Screenskip4.generated";
import * as React from 'react';
export default {
  Screenskip1,
  Screenskip2,
  Screenskip3,
  Screenskip4,
  React
};