import { Qr1 } from "../../../../src/components/images/support/users/Qr1.generated";
import { Qr2 } from "../../../../src/components/images/support/users/Qr2.generated";
import { Qr3 } from "../../../../src/components/images/support/users/Qr3.generated";
import { Qr4 } from "../../../../src/components/images/support/users/Qr4.generated";
import { Qr5 } from "../../../../src/components/images/support/users/Qr5.generated";
import * as React from 'react';
export default {
  Qr1,
  Qr2,
  Qr3,
  Qr4,
  Qr5,
  React
};