import { Refresh1 } from "../../../../src/components/images/support/xero/Refresh1.generated";
import { Refresh2 } from "../../../../src/components/images/support/xero/Refresh2.generated";
import { Refresh3 } from "../../../../src/components/images/support/xero/Refresh3.generated";
import { Refresh4 } from "../../../../src/components/images/support/xero/Refresh4.generated";
import { Refresh5 } from "../../../../src/components/images/support/xero/Refresh5.generated";
import { Refresh6 } from "../../../../src/components/images/support/xero/Refresh6.generated";
import * as React from 'react';
export default {
  Refresh1,
  Refresh2,
  Refresh3,
  Refresh4,
  Refresh5,
  Refresh6,
  React
};