import { Screen16 } from "../../../../src/components/images/support/xero/Screen16.generated";
import { Screen17 } from "../../../../src/components/images/support/xero/Screen17.generated";
import { Screen18 } from "../../../../src/components/images/support/xero/Screen18.generated";
import { Screen19 } from "../../../../src/components/images/support/xero/Screen19.generated";
import * as React from 'react';
export default {
  Screen16,
  Screen17,
  Screen18,
  Screen19,
  React
};