import { Selfserve1 } from "../../../../src/components/images/support/workflows/Selfserve1.generated";
import { Selfserve2 } from "../../../../src/components/images/support/workflows/Selfserve2.generated";
import { Selfserve3 } from "../../../../src/components/images/support/workflows/Selfserve3.generated";
import { Selfserve4 } from "../../../../src/components/images/support/workflows/Selfserve4.generated";
import { Selfserve5 } from "../../../../src/components/images/support/workflows/Selfserve5.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Selfserve1,
  Selfserve2,
  Selfserve3,
  Selfserve4,
  Selfserve5,
  SupportFooter,
  React
};