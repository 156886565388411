import { Visacheck1 } from "../../../../src/components/images/support/visa/Visacheck1.generated";
import { Visacheck2 } from "../../../../src/components/images/support/visa/Visacheck2.generated";
import { Visacheck3 } from "../../../../src/components/images/support/visa/Visacheck3.generated";
import { Visacheck4 } from "../../../../src/components/images/support/visa/Visacheck4.generated";
import { Visacheck5 } from "../../../../src/components/images/support/visa/Visacheck5.generated";
import { Visacheck6 } from "../../../../src/components/images/support/visa/Visacheck6.generated";
import { Visacheck7 } from "../../../../src/components/images/support/visa/Visacheck7.generated";
import { Visacheck8 } from "../../../../src/components/images/support/visa/Visacheck8.generated";
import { Visacheck9 } from "../../../../src/components/images/support/visa/Visacheck9.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Visacheck1,
  Visacheck2,
  Visacheck3,
  Visacheck4,
  Visacheck5,
  Visacheck6,
  Visacheck7,
  Visacheck8,
  Visacheck9,
  SupportFooter,
  React
};