import { Screen6 } from "../../../../src/components/images/support/tasks/Screen6.generated";
import { Screen9 } from "../../../../src/components/images/support/tasks/Screen9.generated";
import { Screen11 } from "../../../../src/components/images/support/tasks/Screen11.generated";
import { Screen11a } from "../../../../src/components/images/support/tasks/Screen11a.generated";
import { Screen10 } from "../../../../src/components/images/support/tasks/Screen10.generated";
import { Screen26 } from "../../../../src/components/images/support/tasks/Screen26.generated";
import { Screen28 } from "../../../../src/components/images/support/tasks/Screen28.generated";
import { Screen30 } from "../../../../src/components/images/support/tasks/Screen30.generated";
import { Screen32 } from "../../../../src/components/images/support/tasks/Screen32.generated";
import * as React from 'react';
export default {
  Screen6,
  Screen9,
  Screen11,
  Screen11a,
  Screen10,
  Screen26,
  Screen28,
  Screen30,
  Screen32,
  React
};