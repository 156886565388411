import { Screen1 } from "../../../../src/components/images/support/getting-started/Screen1.generated";
import { Screen2 } from "../../../../src/components/images/support/getting-started/Screen2.generated";
import { Screen3 } from "../../../../src/components/images/support/getting-started/Screen3.generated";
import { Screen4 } from "../../../../src/components/images/support/getting-started/Screen4.generated";
import { Screen5a } from "../../../../src/components/images/support/getting-started/Screen5a.generated";
import { Screen5 } from "../../../../src/components/images/support/getting-started/Screen5.generated";
import { Screen6 } from "../../../../src/components/images/support/getting-started/Screen6.generated";
import { Screen6a } from "../../../../src/components/images/support/getting-started/Screen6a.generated";
import { Screen7 } from "../../../../src/components/images/support/getting-started/Screen7.generated";
import { Screen8 } from "../../../../src/components/images/support/getting-started/Screen8.generated";
import { Screen9 } from "../../../../src/components/images/support/getting-started/Screen9.generated";
import { Screen10 } from "../../../../src/components/images/support/getting-started/Screen10.generated";
import { Screen11 } from "../../../../src/components/images/support/getting-started/Screen11.generated";
import { Screen12 } from "../../../../src/components/images/support/getting-started/Screen12.generated";
import { Screen13a } from "../../../../src/components/images/support/getting-started/Screen13a.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5a,
  Screen5,
  Screen6,
  Screen6a,
  Screen7,
  Screen8,
  Screen9,
  Screen10,
  Screen11,
  Screen12,
  Screen13a,
  SupportFooter,
  React
};