import { Screen13 } from "../../../../src/components/images/support/getting-started/Screen13.generated";
import { Screen14 } from "../../../../src/components/images/support/getting-started/Screen14.generated";
import { Screen15 } from "../../../../src/components/images/support/getting-started/Screen15.generated";
import { Screen16 } from "../../../../src/components/images/support/getting-started/Screen16.generated";
import { Screen17 } from "../../../../src/components/images/support/getting-started/Screen17.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Screen13,
  Screen14,
  Screen15,
  Screen16,
  Screen17,
  SupportFooter,
  React
};