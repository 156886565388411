import { Screen1 } from "../../../../src/components/images/support/getting-started/Screen1.generated";
import { CopyWorkflow1 } from "../../../../src/components/images/support/workflows/CopyWorkflow1.generated";
import { CopyWorkflow2 } from "../../../../src/components/images/support/workflows/CopyWorkflow2.generated";
import { CopyWorkflow3 } from "../../../../src/components/images/support/workflows/CopyWorkflow3.generated";
import { CopyWorkflow4 } from "../../../../src/components/images/support/workflows/CopyWorkflow4.generated";
import { CopyWorkflow5 } from "../../../../src/components/images/support/workflows/CopyWorkflow5.generated";
import { CopyWorkflow6 } from "../../../../src/components/images/support/workflows/CopyWorkflow6.generated";
import { CopyWorkflow7 } from "../../../../src/components/images/support/workflows/CopyWorkflow7.generated";
import * as React from 'react';
export default {
  Screen1,
  CopyWorkflow1,
  CopyWorkflow2,
  CopyWorkflow3,
  CopyWorkflow4,
  CopyWorkflow5,
  CopyWorkflow6,
  CopyWorkflow7,
  React
};