import { ArchiveUser1 } from "../../../../src/components/images/support/users/ArchiveUser1.generated";
import { ArchiveUser2 } from "../../../../src/components/images/support/users/ArchiveUser2.generated";
import { ArchiveUser3 } from "../../../../src/components/images/support/users/ArchiveUser3.generated";
import { ArchiveUser4 } from "../../../../src/components/images/support/users/ArchiveUser4.generated";
import { ArchiveUser5 } from "../../../../src/components/images/support/users/ArchiveUser5.generated";
import * as React from 'react';
export default {
  ArchiveUser1,
  ArchiveUser2,
  ArchiveUser3,
  ArchiveUser4,
  ArchiveUser5,
  React
};