import * as React from 'react';
import { Global } from '@emotion/react';

export const Fonts: React.FC = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Termoli';
        font-weight: '600';
        src: url("/fonts/TermoliBold.otf") format("opentype");
      }
      `}
  />
);
