import { Video1 } from "../../../../src/components/images/support/videos/Video1.generated";
import { Video2 } from "../../../../src/components/images/support/videos/Video2.generated";
import { Video3 } from "../../../../src/components/images/support/videos/Video3.generated";
import { SupportFooter } from "../../../../src/components/SupportFooter";
import * as React from 'react';
export default {
  Video1,
  Video2,
  Video3,
  SupportFooter,
  React
};